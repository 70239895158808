// assets

import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

// import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import dashboard from './dashboard';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
// constant

const icons = {
  PrecisionManufacturingOutlinedIcon,
  LocationOnOutlinedIcon,
  GroupOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocalActivityIcon
};

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'machines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/enterprise/machines'
    },

    {
      id: 'job',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/enterprise/job'
    },

    {
      id: 'authentication',
      title: 'Users',
      // type: 'collapse',
      type: 'item',
      breadcrumbs: true,
      icon: icons.GroupOutlinedIcon,
      url: '/dashboard/enterprise/user'
    },

    {
      id: 'enterpriseLocations',
      title: 'Locations',

      type: 'item',
      breadcrumbs: true,
      icon: icons.LocationOnOutlinedIcon,
      url: '/dashboard/enterprise/location'
    },
    {
      id: 'enterprisetickets',
      title: 'Tickets',

      type: 'item',
      breadcrumbs: true,
      icon: icons.LocalActivityIcon,
      url: '/dashboard/enterprise/tickets'
    }
  ]
};

const EnterprisePage = {
  items: [dashboard, pages]
};
export default EnterprisePage;
