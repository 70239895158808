import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  // CardHeader,
  Divider,
  // Typography,
  Box,
  IconButton,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid
  // Typography
} from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useNavigate } from 'react-router';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// import { gridSpacing } from 'store/constant';
// import { useGetEnterpriseAllList } from 'hooks/useEnterpriseHooks';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} arrow />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      // darkTitle,
      // secondary,
      shadow,
      sx = {},
      title,
      isButton,
      url,
      isEnable,

      // New props for Select
      showSelect,
      showMachineSelect,
      showMachineSelectEnterprise,
      selectLabel,
      selectLabelMachine,
      selectOptions,
      selectOptionMachines,
      onSelectChange,
      onSelectChangeMachine,
      // onSelectClick,
      selectedValue,
      selectedValueMachine,
      userDetailsType,
      selectLabelMachineEnterprise,
      selectedValueMachineEnterprise,
      onSelectChangeMachineEnterprise,
      selectOptionMachinesEnterprise,
      ...others
    },
    ref
  ) => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    // console.log('🚀 ~ userDetailsType:', userData);
    const theme = useTheme();
    const navigate = useNavigate();

    // const { data: allEnterpriseList } = useGetEnterpriseAllList();
    return (
      <Card
        className="jobs-new-card"
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 25,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          position: 'relative',
          ...sx
        }}
      >
        {/* card header and action */}
        <Box
        // sx={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'space-between',
        //   position: 'absolute',
        //   right: '-25px',
        //   zIndex: '11',
        //   top: '25px'
        // }}
        >
          {title == '' && <CardHeader sx={headerSX} title={''} />}
          {isEnable && isButton && (
            <HtmlTooltip title={'Add' + ' ' + title}>
              <IconButton
                sx={{
                  // mr: 5,
                  // position: 'absolute',
                  // right: '25px',
                  zIndex: 11,
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}
                color="secondary"
                onClick={() => {
                  navigate(url);
                }}
              >
                <NoteAddOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
          )}
          {/* userData?.organizationType */}

          {userDetailsType == 'enterprise' || userDetailsType == 'recycler' ? (
            <>
              <Grid container justifyContent="flex-end">
                {showMachineSelectEnterprise && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabelMachineEnterprise}</InputLabel>
                        <Select
                          value={selectedValueMachineEnterprise}
                          onChange={onSelectChangeMachineEnterprise}
                          label={selectLabelMachineEnterprise}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptionMachinesEnterprise?.map((machines) => (
                            <MenuItem key={machines.machineId} value={machines.machineId}>
                              {machines?.machineId}
                            </MenuItem>
                          ))}
                          {/* {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.id} value={machine.id}>
                              {machine?.machine?.machineId}
                            </MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent="flex-end">
                {showSelect && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabel}</InputLabel>
                        <Select
                          value={selectedValue}
                          onChange={onSelectChange}
                          label={selectLabel}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptions
                            ?.filter((item) => item.enabled == true)
                            ?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}

                {showMachineSelect && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabelMachine}</InputLabel>
                        <Select
                          value={selectedValueMachine}
                          onChange={onSelectChangeMachine}
                          label={selectLabelMachine}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.machineId} value={machine.machineId}>
                              {machine?.machineId}
                            </MenuItem>
                          ))}
                          {/* {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.id} value={machine.id}>
                              {machine?.machine?.machineId}
                            </MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
        {/* content & header divider */}
        {title == '' && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  isButton: PropTypes.bool,
  url: PropTypes.string,

  // New PropTypes for Select
  showSelect: PropTypes.bool,
  showMachineSelect: PropTypes.bool,
  showMachineSelectEnterprise: PropTypes.bool,
  selectLabel: PropTypes.string,
  selectLabelMachine: PropTypes.string,
  selectLabelMachineEnterprise: PropTypes.string,
  selectOptions: PropTypes.array,
  selectOptionMachines: PropTypes.array,
  selectOptionMachinesEnterprise: PropTypes.array,

  onSelectChange: PropTypes.func,
  onSelectChangeMachine: PropTypes.func,
  onSelectChangeMachineEnterprise: PropTypes.func,

  selectedValue: PropTypes.any,
  selectedValueMachine: PropTypes.any,
  selectedValueMachineEnterprise: PropTypes.any,
  isEnable: PropTypes.bool,
  userDetailsType: PropTypes.string
};

export default MainCard;
