import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';
import Location from 'views/pages/location';
import LocationAdd from 'views/pages/location/add';
import LocationEdit from 'views/pages/location/edit';
import JobUrL from 'views/job-url';
import EnterpriseUserAdd from 'views/pages/enterprise/enterprise-user/add';
import EnterpriseUserEdit from 'views/pages/enterprise/enterprise-user/edit';
import EnterpriseUserLocationList from 'views/pages/enterprise/enterprise-user/enterprise-user-location';

import EnterpriseUserTicketList from 'views/pages/enterprise/enterprise-user/enterprise-tickets';
import EnterpriseUserTicketAdd from 'views/pages/enterprise/enterprise-user/enterprise-tickets/add';
import EnterpriseUserMachines from 'views/pages/enterprise/enterprise-user/enterprise-machines';
import EnterpriseLocationAdd from 'views/pages/enterprise/enterprise-user/enterprise-user-location/add';
import EnterpriseLocationEdit from 'views/pages/enterprise/enterprise-user/enterprise-user-location/edit';
import Ticket from 'views/pages/ticket';
import TicketAdd from 'views/pages/ticket/add';
import TicketEdit from 'views/pages/ticket/edit';
import EnterpriseJobList from 'views/pages/enterprise/enterprise-user/enterprise-jobs/EnterpriseJobList';
import EnterpriseJobView from 'views/pages/enterprise/enterprise-user/enterprise-jobs/enterprise-job-edit';
import RetailerUserMachines from 'views/pages/retailer/retailer-machines';
import RetailerJobList from 'views/pages/retailer/retailer-jobs';
import RetailerJobView from 'views/pages/retailer/retailer-jobs/retailer-jobs-edit';
import RetailerUserLocationList from 'views/pages/retailer/retailer-locations';
// import EnterpriseMachine from 'views/pages/enterprise/enterprise-machines';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

//  page routing
const MachinePage = Loadable(lazy(() => import('views/pages/machines')));
const MachineEditPage = Loadable(lazy(() => import('views/pages/machines/edit')));
const OrganizationPage = Loadable(lazy(() => import('views/pages/organizations')));
const OrganizationEditPage = Loadable(lazy(() => import('views/pages/organizations/edit')));
const OrganizationAddPage = Loadable(lazy(() => import('views/pages/organizations/add')));
const UserPage = Loadable(lazy(() => import('views/pages/user')));
const UserAddPage = Loadable(lazy(() => import('views/pages/user/add')));
const UserEditPage = Loadable(lazy(() => import('views/pages/user/edit')));
// const RoleManagementPage = Loadable(lazy(() => import('views/pages/role-management')));
// const RoleManagementEditPage = Loadable(lazy(() => import('views/pages/role-management/edit')));
// const RoleManagementAddPage = Loadable(lazy(() => import('views/pages/role-management/add')));
const ProfilePage = Loadable(lazy(() => import('views/accounts/profile')));
const UserAddUnderOrg = Loadable(lazy(() => import('views/pages/user-org/add')));
const UserUnderOrg = Loadable(lazy(() => import('views/pages/user-org')));
const UserEditUnderOrg = Loadable(lazy(() => import('views/pages/user-org/edit')));
const RedirectingEmailPage = Loadable(lazy(() => import('views/pages/redirecting-emailpage')));
const ForgotPasswordPage = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const RecyclerList = Loadable(lazy(() => import('views/pages/recycler')));
const EnterpriseList = Loadable(lazy(() => import('views/pages/enterprise')));
const RetailerList = Loadable(lazy(() => import('views/pages/retailer')));
const PublicUserList = Loadable(lazy(() => import('views/pages/retailer/retailer-user')));
const RecyclerAddPage = Loadable(lazy(() => import('views/pages/recycler/add')));
const RecyclerEditPage = Loadable(lazy(() => import('views/pages/recycler/edit')));

const RecyclerUserList = Loadable(lazy(() => import('views/pages/recycler/recycler-user/user')));
const RecyclerUserMachine = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-machine')));
const RecyclerUserJobs = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-jobs')));
const RecyclerUserLocation = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations')));
const RecyclerUserTicket = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-tickets')));
const RecyclerUserLocationAdd = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations/add')));
const RecyclerUserLocationEdit = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations/edit')));
const RecyclerUserJobView = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-jobs/view-details')));

const EnterpriseUserList = Loadable(lazy(() => import('views/pages/enterprise/enterprise-user')));
const EnterpriseAddPage = Loadable(lazy(() => import('views/pages/enterprise/add')));
const EnterpriseEditPage = Loadable(lazy(() => import('views/pages/enterprise/edit')));
const RetailerAddPage = Loadable(lazy(() => import('views/pages/retailer/add')));
const RetailerEditPage = Loadable(lazy(() => import('views/pages/retailer/edit')));
const ChangePasswordPage = Loadable(lazy(() => import('views/accounts/change-password')));
const ResetPasswordPage = Loadable(lazy(() => import('views/accounts/reset-password')));
const JobListPage = Loadable(lazy(() => import('views/pages/job')));
// const JobAddPage = Loadable(lazy(() => import('views/pages/job/add')));
const JobEditPage = Loadable(lazy(() => import('views/pages/job/edit')));

// const ProfilePage = Loadable(lazy(() => import('views/accounts/profile/index')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (userRoot) => [
  {
    path: '/',
    element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Navigate to="/dashboard/default" /> : <MinimalLayout />,
    children: [
      {
        path: '/',
        element: <AuthLogin3 />
      },
      {
        path: '/confirm-password',
        element: <RedirectingEmailPage />
      },

      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />
      },

      {
        path: '/reset-password',
        element: <ResetPasswordPage />
      },

      // {
      //   path: '/pages/register/register3',
      //   element: <AuthRegister3 />
      // }
      {
        path: 'job/:id',
        element: <JobUrL />
      }
    ]
  },

  {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
      {
        path: '/dashboard/default',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <DashboardDefault /> : <Navigate to="/" />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <DashboardDefault /> : <Navigate to="/" />
          }
        ]
      },
      // {
      //   path: orgType === 'enterprise' ? 'enterprise/machines' : 'machines',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachinePage /> : <Navigate to="/" />
      // },
      {
        path: 'machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachinePage /> : <Navigate to="/" />
      },

      {
        path: 'retailer/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserMachines /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserMachines /> : <Navigate to="/" />
      },
      {
        path: 'machines/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachineEditPage /> : <Navigate to="/" />
      },
      {
        path: 'organizations',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationPage /> : <Navigate to="/" />
      },
      {
        path: 'organizations/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationEditPage /> : <Navigate to="/" />
      },
      {
        path: 'organizations/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationAddPage /> : <Navigate to="/" />
      },
      //Users under Org
      {
        path: 'organizations/user-org',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserUnderOrg /> : <Navigate to="/" />
      },

      {
        path: 'organizations/user-org/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserAddUnderOrg /> : <Navigate to="/" />
      },
      {
        path: 'organizations/user-org/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserEditUnderOrg /> : <Navigate to="/" />
      },
      {
        path: 'users',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserPage /> : <Navigate to="/" />
      },
      {
        path: 'user/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserAddPage /> : <Navigate to="/" />
      },
      {
        path: 'user/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserEditPage /> : <Navigate to="/" />
      },
      {
        path: 'recycler',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerList /> : <Navigate to="/" />
      },
      {
        path: 'recycler/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerAddPage /> : <Navigate to="/" />
      },

      {
        path: 'recycler/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerEditPage /> : <Navigate to="/" />
      },
      {
        path: 'recycler/user',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserList /> : <Navigate to="/" />
      },
      {
        path: 'recycler/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserMachine /> : <Navigate to="/" />
      },
      {
        path: 'recycler/jobs',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserJobs /> : <Navigate to="/" />
      },
      {
        path: 'recycler/jobs/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserJobView /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocation /> : <Navigate to="/" />
      },
      {
        path: 'recycler/tickets',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserTicket /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocationAdd /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocationEdit /> : <Navigate to="/" />
      },
      {
        path: 'enterprise',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseList /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseAddPage /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseEditPage /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/user',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/user/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserAdd /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/user/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserEdit /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserLocationList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/location/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseLocationAdd /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/location/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseLocationEdit /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/tickets',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserTicketList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/tickets/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserTicketAdd /> : <Navigate to="/" />
      },

      {
        path: 'retailer',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerAddPage /> : <Navigate to="/" />
      },
      {
        path: 'retailer/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerEditPage /> : <Navigate to="/" />
      },

      {
        path: 'retailer/public-user',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <PublicUserList /> : <Navigate to="/" />
      },

      {
        path: 'retailer/location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserLocationList /> : <Navigate to="/" />
      },
      {
        path: 'profile',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ProfilePage /> : <Navigate to="/" />
      },
      {
        path: 'profile/change-password',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ChangePasswordPage /> : <Navigate to="/" />
      },
      // {
      //   path: 'profile/reset-password',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResetPasswordPage /> : <Navigate to="/" />
      // },
      {
        path: 'location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Location /> : <Navigate to="/" />
      },

      {
        path: 'location/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <LocationAdd /> : <Navigate to="/" />
      },

      {
        path: 'location/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <LocationEdit /> : <Navigate to="/" />
      },

      // {
      //   path: orgType === 'enterprise' ? 'enterprise/job' : 'job',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobListPage /> : <Navigate to="/" />
      // },

      {
        path: 'job',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobListPage /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/job',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseJobList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/job/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseJobView /> : <Navigate to="/" />
      },

      {
        path: 'retailer/job',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerJobList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/job/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerJobView /> : <Navigate to="/" />
      },
      // {
      //   path: 'job/add',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobAddPage /> : <Navigate to="/" />
      // },

      {
        path: 'job/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobEditPage /> : <Navigate to="/" />
      },
      {
        path: 'ticket',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Ticket /> : <Navigate to="/" />
      },
      {
        path: 'ticket/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <TicketAdd /> : <Navigate to="/" />
      },

      {
        path: 'ticket/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <TicketEdit /> : <Navigate to="/" />
      }
    ]
  }
];
export default MainRoutes;
