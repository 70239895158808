import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetMachineList = ({ limit, sortOrder, sortField, current, previous, first, last, enterpriseId }) => {
  return useQuery({
    queryKey: ['getMachineData', limit, sortOrder, sortField, current, previous, first, last, enterpriseId],
    queryFn: async () => {
      return await axiosInstance
        // .get(
        //   `${endpoints.machines}/all?previous=${previous}&current=${current}&sortField=${sortField}&limit=${limit}&sortOrder=${sortOrder}&first=${first}&last=${last}&enterpriseId=${enterpriseId}`
        // )
        .post(`${endpoints.machines}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
          // enterpriseId:enterpriseId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useMchineUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.machines}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// export const useMchineUpdate = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .patch(`${endpoints.machines}/${data.id} `, data)
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//        queryClient.refetchQueries('getMachineData');
//       // queryClient.refetchQueries('getLocationDetailsById');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

// export const useMchineUpdate = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async (data) => {
//       const { enterprise, recycler, ...rest } = data;

//       const requestBody = enterprise
//         ? {
//             enterprise, // Selected enterprise id
//             location: data.location, // Selected either retailer or enterprise location id
//             retailer: data.retailer, // Selected retailer id
//             price: data.price // Entered price in Number
//           }
//         : {
//             recycler, // Selected recycler id
//             location: data.location, // Selected recycler location id
//             price: data.price // Entered price in Number
//           };

//       return await axiosInstance
//         .patch(`${endpoints.machines}/${data.id}`, requestBody)
//         .then((res) => {
//           if (res?.status === 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//       queryClient.refetchQueries('getMachineData');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

export const useGetMachineById = ({ id }) => {
  return useQuery({
    queryKey: ['getMachineDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machines}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////all Machine List

export const useGetAllMachineList = () => {
  return useQuery({
    queryKey: ['getAllMachineListData'],
    queryFn: async () => {
      return await axiosInstance

        .post(`${endpoints.machines}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetJobEnterpriseMachineList = ({ enterprise }) => {
  return useQuery({
    queryKey: ['getJobEnterpriseMachineListData', enterprise],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          enterprise: enterprise
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
