import React from 'react';

const RetailerUserMachines = () => {
  return (
    <>
      <div>RetailerUserMachines</div>
    </>
  );
};

export default RetailerUserMachines;
