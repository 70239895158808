// assets

import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import dashboard from './dashboard';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// constant

const icons = {
  PrecisionManufacturingOutlinedIcon,

  StorefrontOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocationOnOutlinedIcon
};

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'retailermachines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/retailer/machines'
    },
    {
      id: 'retailerjob',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/retailer/job'
    },
    {
      id: 'retailerLocations',
      title: 'Locations',

      type: 'item',
      breadcrumbs: true,
      icon: icons.LocationOnOutlinedIcon,
      url: '/dashboard/retailer/location'
    }
    // {
    //   id: 'authentication',
    //   title: 'Users',
    //   // type: 'collapse',
    //   type: 'item',
    //   breadcrumbs: true,
    //   icon: icons.StorefrontOutlinedIcon,
    //   url: '/dashboard/retailer/public-user'
    // }
  ]
};

const RetailerPage = {
  items: [dashboard, pages]
};
export default RetailerPage;
