import React from 'react';

const RetailerJobList = () => {
  return (
    <>
      <div>RetailerJobList</div>
    </>
  );
};

export default RetailerJobList;
