import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import img from 'assets/images/ShredBox.png';
import { useGetJobUrlId } from 'hooks/useJobUrlHooks';
const JobUrL = () => {
  let { id } = useParams();

  const { data: jobDetails } = useGetJobUrlId({
    id: id
  });

  return (
    <>
      <img src={img} className="srd-logo-new" alt="img" />
      <div className="shredbox-start shredbox-start2">
        <div className="shredboxmiddle">
          {/* <div className='shrd-two-logo'>
<img src={img} className="srd-logo" alt="img"/>
<img src={certificate} className="srd-certificate-logo" alt="img1"/>
</div> */}
          <div className="shredboxmiddlenew">
            {/* <h1>CERTIFICATE</h1>
<h3>
<div className="h3-styleline1" ></div>OF DESTRUCTION<div className="h3-styleline2" ></div>
</h3> */}
            {/* <p >This document certifies that the will be destroyed in accordance with all applicable Federal, State, and Local Laws including but not limited to if applicable:
NIST 800-88, 40 C.F.R. pts. 239-282; 40 C.F.R. pt. 260; Public law 104-142; 18 USC 1362; and any other applicable Law governing the particular items being destroyed.
All destroyed material will be recycled by a recycler certified to the R2v3 Standard. Learn more about the R2v3 Standard at www.sustainableelectronics.org.
</p> */}
            <div className="main-srd-bx-start">
              {/* <ul >
  <li ><span >Machine ID: </span>[1234]</li>
  <li ><span >Location: </span>230 1st St, Berryville, VA22611</li>
</ul> */}
              <div className="main-srd-bx-start-new">
                <div className="main-srd-bx-start-new1">
                  <div className="table-responsive">
                    <table>
                      <tr>
                        <td>JOB:</td>
                        {/* <td>{jobDetails?.jobId ? jobDetails?.jobId : 'N.A'}</td> */}
                        <td>{jobDetails?.jobId}</td>
                      </tr>
                      <tr>
                        <td>Name:</td>
                        <td>
                          {jobDetails?.user?.firstName} {jobDetails?.user?.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>E-mail:</td>
                        <td>{jobDetails?.user?.email}</td>
                      </tr>
                      <tr>
                        <td>Address:</td>
                        <td>{jobDetails?.user?.address}</td>
                      </tr>
                      <tr>
                        <td>Phone:</td>
                        <td>{jobDetails?.user?.phone}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="main-srd-bx-start-new2">
                  <h4>DESTROYED</h4>
                  <div className="table-responsive">
                    <table>
                      <tr>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Serial</th>
                      </tr>
                      <tr>
                        {jobDetails?.drives?.map((item, i) => (
                          <td key={i}>{item?.make}</td>
                        ))}

                        {jobDetails?.drives?.map((item, i) => (
                          <td key={i}>{item?.model}</td>
                        ))}
                        {jobDetails?.drives?.map((item, i) => (
                          <td key={i}>{item?.serialId}</td>
                        ))}
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="srdlastbox">
              <div className="srdlastbox1">
                <p>
                  <span>Machine ID:</span> {jobDetails?.machine?.machineId}
                </p>
                <p>
                  <span>Location: </span> {jobDetails?.location?.name}
                </p>
              </div>
              <div className="srdlastbox2">
                <p>
                  <span>Date:</span>{' '}
                  {moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                    ? moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                    : jobDetails?.jobDate}
                </p>
              </div>
            </div>
            <div className="download-btn-start">
              <a
                href={'https://shredbox.vercel.app/api/v1/drive-crush/certificate/ce7cdd93-2e73-40a6-9cf5-fb4d12f4efea'}
                download="Certificate"
              >
                <button className="download-btn">Save Certificate</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobUrL;
