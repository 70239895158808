import { useEffect } from 'react';

// MUI Imports
// import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// import IconButton from '@mui/material/IconButton';
import { CircularProgress, DialogContentText } from '@mui/material';
import { Box } from '@mui/system';

const DeleteDialog = ({ open, setOpen, title, handleSubmit, isDeleteLoading, subTitle }) => {
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isDeleteLoading) {
      setOpen(false);
    }
  }, [isDeleteLoading, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth={false}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      className="dlte_file_mdlnw"
    >
      <DialogTitle
        id="alert-dialog-title"
        variant="span"
        style={{
          textTransform: 'capitalize'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{subTitle}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            style={{
              color: '#fff'
            }}
            color="primary"
            disabled={isDeleteLoading}
          >
            Yes
            {isDeleteLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#16B1FF',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Button>
        </Box>
        <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
          Cancel
        </Button>
        {/* </Box> */}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
