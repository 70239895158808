// This file consists all the endpoints
export const endpoints = {
  auth: '' /* authentication endpoint */,
  organizations: 'v1/organizations' /* categories endpoint */,
  users: 'v1/users',
  roles: 'v1/roles',
  machines: 'v1/machines',
  recylers: 'v1/recyclers',
  enterprises: 'v1/enterprises',
  retailers: 'v1/retailers',
  locations: 'v1/locations',
  jobs: 'v1/jobs',
  ticket: 'v1/tickets'
};
