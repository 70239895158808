import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
  Box,
  Chip,
  Skeleton,
  Link,
  DialogActions,
  Button
  // CircularProgress
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@emotion/react';
import { useEnterpriseTicketStatusUpdate, useGetEnterpriseTicketById } from 'hooks/useTickets';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { styled } from '@mui/material/styles';
import DeleteDialog from 'ui-component/delete-dialog';
import { useLocation } from 'react-router-dom';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const ViewTicket = ({ open, setOpen, details }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const location = useLocation();
  // Use URLSearchParams to extract the query parameters from the URL
  const queryParams = new URLSearchParams(location?.search);
  const viewTicketID = queryParams?.get('viewTicketID');
  details = {
    ...details,
    id: details?.id ? details?.id : viewTicketID
  };
  const { data: ticketDetails, isPending: isLoadingTicketDetails } = useGetEnterpriseTicketById({
    id: details?.id
  });
  const { mutate: updatedEnterpriseTicketStatus, isPending: isLoadingUpdateEnterpriseTicketStatus } = useMutation({
    mutationFn: useEnterpriseTicketStatusUpdate
  });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {isLoadingTicketDetails ? (
            <>
              <Skeleton variant="text" width={300} />
            </>
          ) : (
            <>
              {`View Ticket : ${ticketDetails?.id}`}{' '}
              <Chip
                size="small"
                label={
                  ticketDetails?.status === 'In Review'
                    ? 'In Review'
                    : ticketDetails?.status === 'Rejected'
                    ? 'Rejected'
                    : ticketDetails?.status === 'Requested'
                    ? 'Requested'
                    : ticketDetails?.status === 'Accepted'
                    ? 'Accepted'
                    : 'Resolved'
                }
                style={{
                  color: '#fff',
                  fontWeight: '400',
                  backgroundColor:
                    ticketDetails?.status === 'In Review'
                      ? '#dbc115'
                      : ticketDetails?.status === 'Rejected'
                      ? '#ff1744'
                      : ticketDetails?.status === 'Requested'
                      ? '#3d5afe'
                      : ticketDetails?.status === 'Accepted'
                      ? '#ae30c2'
                      : '#30c42b'
                }}
              />
            </>
          )}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <DialogContent dividers>
          {isLoadingTicketDetails ? (
            <FormView />
          ) : (
            <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-prefix"> Subject</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-fname"
                        type="text"
                        name="subject"
                        label="Subject"
                        inputProps={{}}
                        autoComplete="off"
                        disabled
                        value={ticketDetails?.subject ?? ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-prefix">Reason</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-fname"
                        type="text"
                        name="reason"
                        label="Reason"
                        inputProps={{}}
                        autoComplete="off"
                        disabled
                        value={ticketDetails?.reason ?? ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-prefix"> Machine</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-fname"
                        type="text"
                        name="machine"
                        label="Machine"
                        inputProps={{}}
                        autoComplete="off"
                        disabled
                        value={ticketDetails?.machine?.machineId ?? ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-prefix"> Priority </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-fname"
                        type="text"
                        name="priority"
                        label="Priority"
                        inputProps={{}}
                        autoComplete="off"
                        disabled
                        value={ticketDetails?.priority ?? ''}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }} className="ticket_detailstextarea">
                      <InputLabel htmlFor="outlined-adornment-prefix">Notes</InputLabel>
                      <OutlinedInput
                        value={ticketDetails?.notes ?? 0}
                        multiline
                        rows={3}
                        disabled
                        fullWidth
                        variant="outlined"
                        name="notes"
                        label="Notes"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid spacing={gridSpacing} sx={{ alignContent: 'center' }}>
                      <div style={{ padding: '10px 15px 15px' }} className="field-input-all">
                        <Typography variant="h5" component={'span'} className="font-medium">
                          Related Files/Documents
                        </Typography>
                        <Grid
                          spacing={gridSpacing}
                          container
                          item
                          xs={12}
                          sm={12}
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          {ticketDetails?.files?.length > 0 ? (
                            <>
                              {ticketDetails?.files?.map((item, i) => (
                                <Grid item xs={12} md={4} key={i} lg={4} sm={4}>
                                  <div className="chse-prefix-newbx">
                                    {/* <h3 >View</h3> */}
                                    <Typography variant="span" component="h3">
                                      <Link
                                        href={item?.url}
                                        target="_blank"
                                        sx={{
                                          color: '#fff',
                                          textDecoration: 'none'
                                        }}
                                      >
                                        View
                                      </Link>
                                    </Typography>
                                    {!!item?.mimetype && item?.mimetype?.startsWith('application/pdf') ? (
                                      <>
                                        <iframe
                                          src={`${item?.url}#toolbar=0`}
                                          height="500px"
                                          width="100%"
                                          title={item?.name}
                                          style={{ border: 'none', overflow: 'hidden', cursor: 'pointer' }}
                                        ></iframe>{' '}
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={item?.url}
                                          alt={item?.name}
                                          style={{
                                            width: '100%',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </>
                                    )}
                                    <div className="chse-prefix-captionbx">
                                      <div className="chse-prefix-caption-first">
                                        <h4>{item?.name}</h4>
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </Grid>
                              ))}
                            </>
                          ) : (
                            <>
                              <StyledGridOverlay>
                                <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                                  <g fill="none" fillRule="evenodd">
                                    <g transform="translate(24 31.67)">
                                      <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                                      <path
                                        className="ant-empty-img-1"
                                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                      />
                                      <path
                                        className="ant-empty-img-2"
                                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                      />
                                      <path
                                        className="ant-empty-img-3"
                                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                      />
                                    </g>
                                    <path
                                      className="ant-empty-img-3"
                                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                    />
                                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                                      <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                                    </g>
                                  </g>
                                </svg>
                                <Box sx={{ mt: 1 }}>No data found!</Box>
                              </StyledGridOverlay>
                            </>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {ticketDetails?.status === 'In Review' && (
          <DialogActions>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                onClick={() => {
                  setOpenConfirmationModal(!openConfirmationModal);
                }}
                style={{
                  color: '#fff'
                }}
                color="primary"
                disabled={isLoadingUpdateEnterpriseTicketStatus}
              >
                Accept
              </Button>
            </Box>
            <Button
              variant="outlined"
              type="reset"
              color="error"
              onClick={() => {
                setOpenRejectModal(!openRejectModal);
              }}
              disabled={isLoadingUpdateEnterpriseTicketStatus}
            >
              Reject
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <DeleteDialog
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        title={'Confirmation !'}
        subTitle={'Are you sure to accept the closure request?'}
        handleSubmit={() => {
          updatedEnterpriseTicketStatus(
            {
              ticketID: ticketDetails?.id,
              status: 'Resolved',
              isEnterprise: true
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.success);
                  queryClient.refetchQueries({
                    queryKey: ['getEnterpriseTicketDataList'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getEnterpriseTicketDetailsById'],
                    type: 'active'
                  });
                  setOpenConfirmationModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpenConfirmationModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpenConfirmationModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateEnterpriseTicketStatus}
      />

      <DeleteDialog
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        title={'Confirmation !'}
        subTitle={'Are you sure to reject the closure request?'}
        handleSubmit={() => {
          updatedEnterpriseTicketStatus(
            {
              ticketID: ticketDetails?.id,
              status: 'Accepted',
              isEnterprise: true
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.success);
                  queryClient.refetchQueries({
                    queryKey: ['getEnterpriseTicketDataList'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getEnterpriseTicketDetailsById'],
                    type: 'active'
                  });
                  setOpenRejectModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpenRejectModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpenRejectModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateEnterpriseTicketStatus}
      />
    </>
  );
};

export default ViewTicket;
