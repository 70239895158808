import React from 'react';

const RetailerJobView = () => {
  return (
    <>
      <div>RetailerJobView</div>
    </>
  );
};

export default RetailerJobView;
