import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import TicketForm from '../ticket-form';
import { useState } from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useGetTicketReasonById, useGetTicketReasonEditedList } from 'hooks/useTicketReasonHooks';
const TicketEdit = () => {
  let { id } = useParams();

  const { data: ticketReasonDetails } = useGetTicketReasonById({
    id: id.slice(1)
  });
  const { mutate: updateTicketReason, isPending: isLoadingTicketreason } = useGetTicketReasonEditedList();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <>
        {isLoading ? (
          <FormView />
        ) : (
          <>
            <BreadcrumbsForPage
              name="Edit Ticket Reason "
              obj={{ title: 'All Ticket Reasons', title2: 'Edit Ticket Reason', url: '/dashboard/ticket' }}
            />
            <MainCard>
              <Formik
                initialValues={{
                  reason: ''
                }}
                validationSchema={Yup.object().shape({
                  reason: Yup.string().required('Reason is required')
                })}
                onSubmit={(value) => {
                  const submitData = {
                    id: id.slice(1),
                    reason: value.reason.trim()
                  };
                  updateTicketReason(submitData);
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched }) => (
                  <>
                    <TicketForm
                      btnName="Update"
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      values={values}
                      isLoadingTicket={isLoadingTicketreason}
                      touched={touched}
                      ticketReasonDetails={ticketReasonDetails}
                    />
                  </>
                )}
              </Formik>
            </MainCard>
          </>
        )}
      </>
    </>
  );
};

export default TicketEdit;
